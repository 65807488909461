import React from "react";
import LRNavigationBar from "../components/LRNavigationBar";
import Footer from "../sections/Footer";

const layouts = ({ children }) => {
  return (
    <div className="min-h-screen">
      <header className="relative">
        <LRNavigationBar />
      </header>
      {children}
      <Footer />
    </div>
  );
};

export default layouts;
